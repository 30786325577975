@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
body {
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #555;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  background-color: #f5f5f5;
}

